const token = '78732aa404e3681f5af6cfae809f30e4668ccb8f08611405fb5d0b3f2ccbc5189ab536a10a359659e036b41a91508aa6ffab11c80ee67a1239d5caa777610ae9434154cffe4695583fb5d5ad10e3e7bbbe92e25c09f8186f511e66c7bcdf4a2edf3f75848628232f44f8c4f02dd9d8970f9a5a44eb51e6a1dcaf8e21f91679b0';

// write a function that fetches data from the backend
// using the token and the url given
export async function retrieveDataFromBackend(component, locale = 'de', populate = []) {
  locale = locale.substring(0, 2);
  const url = `https://backend.stelldichein.at/api/${component}?locale=${locale}&populate=${populate.join(',')}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json());
}

