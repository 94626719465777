import DefaultNavbar from "../DefaultNavbar";
import { useTranslation } from 'react-i18next';

export default function Events() {

    const { t } = useTranslation();

    const token = '';

    return (
        <div className="events">
            <DefaultNavbar/>

            <div className="carousel-image">

 

            <div className="floatingBox floatingBox-legals">

                <div className="floatingBox1">
                        <div>
                            <h1>{ t('events.promotion.title') }</h1>
                            <span>
                                <p>{ t('events.promotion.content1') }</p>
                                { t('events.promotion.content2') } <a href="mailto:office@stelldichein.at">office@stelldichein.at</a>
                            </span>
                        </div>
                    </div>

                    <div></div>

                </div>
            </div>
        
        </div>
    );
}
