import DefaultNavbar from "../DefaultNavbar";
import { useTranslation } from 'react-i18next';

export default function Legals() {

    const { t } = useTranslation();

    return (
    <div className="legals">
        <DefaultNavbar/>

        <div className="carousel-image">

            <div className="floatingBox floatingBox-legals">

                <div className="floatingBox1">
                    <div>
                        <h1>{ t('about.legal') }</h1>
                        <hr/>
                        <p>Informationspflichten nach UGB, ECG sowie Offenlegungspflicht nach MedienG</p>
                        <p>WOBI Gastrobeteiligungs GmbH</p>
                        <p>Himmelpfortgasse 6, A-1010 Wien</p>
                        <p>Unternehmensgegenstand: Gastronomie & Handel</p>
                        <p>Email: office(at)stelldichein.at</p>
                        <p>Tel: +43 664 5169253</p>
                        <p>UID: ATU74170103</p>
                        <p>Firmenbuchnummer: 507963f</p>
                        <p>Mitglied der WKW, Fachgruppe Kaffeehäuser</p>
                    </div>
                </div>

                <div></div>

            </div>
        </div>
    </div>
    );
}
