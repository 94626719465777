import DefaultNavbar from "../DefaultNavbar";
import { useTranslation } from 'react-i18next';

export default function About() {

    const { t } = useTranslation();

    return (
    <div className="about-us">
        <DefaultNavbar/>

        <div className="carousel-image">

            <div className="floatingBox">

                <div></div>

                <div className="floatingBox1">
                    
                    <div>
                        <h1>{ t('about.who_are_we') }</h1>
                        <span>verabredetes [heimliches] Treffen von zwei Verliebten; Rendezvous</span>
                    </div>
                </div>

            </div>

            <div className="floatingBox">

                <div className="floatingBox2">
                    <div>
                        
                    </div>
                </div>

                <div></div>

            </div>
        </div>
    </div>
    );
}
