import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Button } from 'react-bootstrap';
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons';
import DefaultNavbar from "../DefaultNavbar";
import { useTranslation } from 'react-i18next';
import { retrieveDataFromBackend } from "../../api";

export default function Food() {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [menu, setMenu] = useState('/uploads/test.pdf');
    const { t, i18n } = useTranslation();

    useEffect(() => {
      const fetchData = async () => {
        const response = await retrieveDataFromBackend('menu', i18n.language, ['Menu', 'Menu_mobile']);
        if (window.innerWidth < 992) {
          setMenu('https://backend.stelldichein.at' + response.data.attributes.Menu_mobile.data.attributes.url);
        } else {
          setMenu('https://backend.stelldichein.at' + response.data.attributes.Menu.data.attributes.url);
        }
      };
      fetchData();
    }, [menu, i18n.language]);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    return (
        <div className="menu">
            <DefaultNavbar/>
            <div className='defaultContainer'>
                <div className='menu-header'>
                    <Button
                        disabled={pageNumber <= 1}
                        onClick={previousPage}
                        variant="dark"
                    >
                        <ArrowLeft/> {t('menu.previous')}
                    </Button>
                    &nbsp;
                    <Button
                        disabled={pageNumber >= numPages}
                        onClick={nextPage}
                        variant="dark"
                    >
                        {t('menu.next')} <ArrowRight/>
                    </Button>
                </div>
                <div className='pdf-box'>
                    <Document
                        file={menu}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page className={"PDFPage"} pageNumber={pageNumber} height={window.innerHeight * 0.8} />
                    </Document>
                </div>
            </div>
        </div>
    );
}
