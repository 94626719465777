import DefaultNavbar from "../DefaultNavbar";
import CustomMap from "./CustomMap";

export default function Location() {
  return (
    <div>
        <DefaultNavbar/>
        <CustomMap/>
    </div>
  );
}
