import DefaultNavbar from "../DefaultNavbar";
import { useTranslation } from 'react-i18next';
import { ArrowDown } from 'react-bootstrap-icons';
import { HashLink as Link } from 'react-router-hash-link';
import React, { useEffect, useState } from 'react';
import { retrieveDataFromBackend } from "../../api";

function Home() {

    const defaultOpeningTimes = [
      {
        Wochentag: "",
        Zeit: "",
      },
      {
        Wochentag: "",
        Zeit: "",
      },
      {
        Wochentag: "",
        Zeit: "",
      }
    ];
    const { t, i18n } = useTranslation();
    const [openingTimes, setOpeningTimes] = useState(defaultOpeningTimes);

    useEffect(() => {
      const fetchData = async () => {
        const response = await retrieveDataFromBackend('home', i18n.language, ['Opening_times']);
        setOpeningTimes(response.data.attributes.Opening_times);
      };
      fetchData();
    }, []);

    return (
        <div className="home">
            <DefaultNavbar/>

            <div className="carousel-image">

                <div className="floatingBox box1" id="page2">
                    <div className="floatingBox1">
                        <div className="leftDivStelldichein">
                            <img src="https://backend.stelldichein.at/uploads/Design_ohne_Titel_5720eee27c.png" width="100%"/>
                        </div>
                        <div className="rightDivStelldichein">
                            <h1>STELL|DICH|EIN</h1>
                            <hr/>
                            <h2>{t('home.explaination_stelldichein')}</h2>
                            <i> {t('home.explaination_stelldichein_label')}</i>
                            <img src="https://backend.stelldichein.at/uploads/Blaetter_05_2bf8d6850f.png" className="leafRightDivStelldichein"/>
                        </div>
                    </div>

                    <div></div>
                </div>

                <div className="floatingBox box2">
                    <div className="carretDown">
                        <Link to="/#page2" className="carretDownLink"><ArrowDown/></Link>
                    </div>

                    <div className="floatingBox2">
                        <div>
                            <h1>MEIEREI IM VOLKSGARTEN</h1>
                            <hr/>
                            <h2>{t('home.title')}</h2>
                            <div className="opening-times">
                                <div className="opening-times-line">
                                    <div>{openingTimes[0].Wochentag}</div>
                                    <div>{openingTimes[0].Zeiten}</div>
                                </div>
                                <div className="opening-times-line">
                                    <div>{openingTimes[1].Wochentag}</div>
                                    <div>{openingTimes[1].Zeiten}</div>
                                </div>
                                <div className="opening-times-line">
                                    <div>{openingTimes[2].Wochentag}</div>
                                    <div>{openingTimes[2].Zeiten}</div>
                                </div>
                            </div>
                        </div>
                        <div className="logoBigContainer">
                            <img className="logoBig" src="https://backend.stelldichein.at/uploads/Stelldichein_Logo_einzeln_Zeichenflaeche_1_07_5949c21a82.png" width="100%"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;