import './App.css';
import Home from  './components/Home';
import About from './components/About';
import Location from './components/Location';
import Food from './components/Food';
import Events from './components/Events';
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Legals from './components/Legals';
import Lounge from './components/Lounge';

export default function App() {

    const { i18n } = useTranslation();

    useEffect(() => {
        document.title = "Stelldichein Meierei im Volksgarten";

        // check if language is set in localStorage and set it respectively
        if (localStorage.getItem('language')) {
            i18n.changeLanguage(localStorage.getItem('language'));
        }
    }, [i18n]);
    
    return (
    <div className="App">
        <Router>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/location" element={<Location/>}/>
                <Route path="/food" element={<Food/>}/>
                <Route path="/events" element={<Events/>}/>
                <Route path="/legals" element={<Legals/>}/>
                <Route path="/lounge" element={<Lounge/>}/>
            </Routes>
        </Router>
    </div>
    );
};