import DefaultNavbar from "../DefaultNavbar";
import { useTranslation } from 'react-i18next';

export default function Lounge() {

    const { t } = useTranslation();

    return (
        <div className="lounge">
            <DefaultNavbar/>

            <div className="carousel-image">

                <div className="floatingBox">

                    <div></div>

                    <div className="floatingBox1">
                        
                        <div>
                            <h1>{ t('lounge.title') }</h1>
                            <span>verabredetes [heimliches] Treffen von zwei Verliebten; Rendezvous</span>
                        </div>
                    </div>

                </div>

                <div className="floatingBox">

                    <div className="floatingBox2">
                        <div>
                            <h1>{ t('lounge.opening_times') }</h1>
                            <span>verabredetes [heimliches] Treffen von zwei Verliebten; Rendezvous</span>
                        </div>
                    </div>

                    <div></div>

                </div>
            </div>
        </div>
    );
}
