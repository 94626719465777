import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            navbar: {
                home: "Home",
                food: "Menu",
                about: "About",
                events: "Events",
                location: "Location",
                language: "Language",
                legals: "Legals",
                lounge: "Lounge"
            },
            home: {
                title: "Cafe & Lounge in the heart of the Volksgarten.",
                opening_times: {
                    line1: {
                        text: "Monday - Wednesday: ",
                        time: "10 AM - 7 PM"
                    },
                    line2: {
                        text: "Thursday - Saturday: ",
                        time: "9 AM - 9 PM"
                    },
                    line3: {
                        text: "Sunday: ",
                        time: "9 AM - 7 PM"
                    },
                },
                explaination_stelldichein: "arranged [secret] meeting of two lovers; date",
                explaination_stelldichein_label: "— Definition of the term \"Stelldichein\""
            },
            menu: {
                next: "Next",
                previous: "Previous",
            },
            about: {
                legal: 'LEGALS',
                who_are_we: 'Who are we?',
            },
            lounge: {
                title: "COME AND SIT WITH US",
                opening_times: ""
            },
            events: {
                next: "Upcoming events",
                promotion: {
                    "title": "YOUR EVENT",
                    "content1": "Are you looking for a unique location for your next event? The Stelldichein is ideal for numerous settings. Due to our spacious guest garden, we can also cater for larger groups during the summer months. Whether you are planning a corporate event, private party or wedding reception, our staff will ensure your event is a success!",
                    "content2": "If you are interested, please send us a short inquiry to"
                },
            }
        }
    },
    de: {
        translation: {
            navbar: {
                home: "Home",
                food: "Speisekarte",
                about: "Über uns",
                events: "Events",
                location: "Anfahrt",
                language: "Sprache",
                legals: "Impressum",
                lounge: "Lounge"
            },
            home: {
                title: "Cafe & Lounge im Herzen des Volksgarten.",
                opening_times: {
                    line1: {
                        text: "Montag - Mittwoch: ",
                        time: "10 bis 19 Uhr"
                    },
                    line2: {
                        text: "Donnerstag - Samstag: ",
                        time: "9 bis 21 Uhr"
                    },
                    line3: {
                        text: "Sonntag: ",
                        time: "9 bis 19 Uhr"
                    },
                },
                explaination_stelldichein: "verabredetes [heimliches] Treffen von zwei Verliebten; Rendezvous",
                explaination_stelldichein_label: "— Definition des Begriff \"Stelldichein\" im Duden"
            },
            menu: {
                next: "Weiter",
                previous: "Zurück",
            },
            about: {
                legal: 'IMPRESSUM',
                who_are_we: 'Wer wir sind?',
            },
            lounge: {
                title: "STELL DICH REIN INS STELLDICHEIN",
                opening_times: "Zum Afterwork oder gemütlich zwischendurch"
            },
            events: {
                next: "Die nächsten Veranstaltungen",
                promotion: {
                    "title": "IHRE VERANSTALTUNG",
                    "content1": "Suchen Sie nach einer einzigartigen Location für Ihre nächste Veranstaltung? Das Stelldichein bietet sich für zahlreiche Settings ideal an. Aufgrund unseres weitläufigen Gastgartens können wir während der Sommermonate auch größere Gruppen bewirten. Ob Sie eine Firmenveranstaltung, eine private Feier oder einen Hochzeitsempfang planen, unsere Mitarbeiter sorgen dafür, dass Ihre Veranstaltung ein Erfolg wird!",
                    "content2": "Bei Interesse schreiben Sie uns bitte ein kurzes, unverbindliches E-Mail an"
                },
            }
        }
    }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    //lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;