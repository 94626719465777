import GoogleMapReact from 'google-map-react';

const PinComponent = () => <div><span className='pin'>📍</span><div className='logo-pin'><img width="100%" src='https://backend.stelldichein.at/uploads/logo_98f1d72827.png' alt='Logo der Meierei im Volksgarten'/><span className='restaurant-appendix-text'>MEIEREI IM VOLKSGARTEN</span></div></div>;

export default function CustomMap() {
  const defaultProps = {
    center: {
      lat: 48.207921,
      lng: 16.362565
    },
    zoom: 17
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '90vh', width: '100%' }}>
      <GoogleMapReact bootstrapURLKeys={{ key: "AIzaSyCHjfIxRdr0HoBoQ_DdLIdMNmX4TKKKlLA" }} defaultCenter={defaultProps.center} defaultZoom={defaultProps.zoom}>
        <PinComponent lat={defaultProps.center.lat} lng={defaultProps.center.lng} />
      </GoogleMapReact>
    </div>
  );
}